<template>
  <div class="flex flex-wrap w-full justify-center">
    <div class="w-9/12 flex flex-wrap justify-center pb-24">
      <p class="mt-36 font-sans text-xl base-font-gray-lighter-210 text-center w-full">Your bucket has been created.</p>
      <p class="font-sans text-xl base-font-gray-lighter-210 text-center w-full">You can now add a YouTube channel.</p>
      <p class="mt-8 text-6xl text-black font-black text-center w-full">Add your First Channel</p>

      <div class="flex flex-wrap w-full text-center mt-32">
        <div class="w-5/12 relative px-4 pt-4">
          <p class="font-sansDemi text-2xl text-black">Manually</p>
          <p class="mt-16 font-sans text-base base-font-gray-200">
            Add a YouTube channel manually. Useful if you exactly know what you’re looking for.
          </p>
          <!-- <router-link 
            :to="`/buckets/`"
            class="mt-16 px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
          >
            Add a Channel Manually
          </router-link> -->
        </div>
        <div class="w-2/12">
          <div class="w-16 h-16 rounded-full border border-grey-light-180">
            <span class="flex items-center justify-center mt-4 font-sansDemi text-2xl text-black italic leading-none">or</span>
          </div>
        </div>
        <div class="w-5/12 relative px-4 pt-4">
          <div class="relative">
            <p class="font-sansDemi text-2xl text-black">Discovery</p>
            <p class="absolute top-full left-1/2 -translate-x-half font-sans text-2xl base-font-gray-210">(recommended)</p>
          </div>
          <p class="mt-16 font-sans text-base base-font-gray-200">
            The RadiTube Discovery tool let’s you find channels that share similar audiences.
          </p>
          <router-link 
            to="/discovery"
            class="mt-16 px-3 py-1 border border-grey-darker-100 inline-block font-monoDemi text-xs rounded-2xl whitespace-nowrap text-black"
          >
            Start Channel Discovery
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
  },
  data: () => {
    return {
      searchTerm: `"do your own research"`
    }
  },
  methods: {
    setSearch(){
      this.$router.push(`/search/q/${this.searchTerm}/bucket/bid_EqxN2Lm54`)
    }
  }
}
</script>

<style lang="scss">
.step{
  transform: translateX(-50%) translateY(-50%);
  &-one{
    background: linear-gradient(288.43deg, #913EFB -312.5%, #B1DADD 278.41%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &-line{
      top: -98px;
    }
  }
  &-four{
    &-line{
      top: -154px;
    }
  }
}
.figure{
  &-left{
    bottom: -136px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
}
.add-channels{
  top: -50%;
  right: -18%;
  &::before{
    content: '';
    position: absolute;
    width: 2.3rem;
    height: 1px;
    background-color: #C4C4C4;
    top: 10px;
    left: -54px;
  }
}
.promo{
  top: -75%;
  right: -40%;
}
</style>